import { GetServerDataProps, GetServerDataReturn, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import BlogPage from '../components/BlogPage';
import { BlogProvider } from '../components/BlogPage/context';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IBlogPage } from '../types/strapi/blogPage';
import { IGlobalServerRuntimeData } from '../types/strapi/global';
import { IPostType, TBlogPost } from '../types/strapi/posts';

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    blogPage: IBlogPage,
    posts: Array<TBlogPost>,
    postTypes: Array<IPostType>,
    postsCount: number,
    tag: number | null
}

type TProp = TPageProp<IServerRuntimeData>;

const Blog: React.FC<TProp> = ({ serverData }) => {
    const isHiddenPage = true;

    useEffect(() => {
        if(isHiddenPage) {
            navigate('/');
        }
    }, []);

    if(isHiddenPage) {
        return null;
    }

    return (
        <BlogProvider>
            <BlogPage data={serverData} />
        </BlogProvider>
    );
};

export default Blog;

const query = `
  query BlogPage ($locale: String, $where: JSON) {
    ${GLOBAL_QUERY}
    blogPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
    }
    posts (limit: 7, sort: "date:desc", start: 0, where: $where) {
      id
      code
      title
      date
      readingTime
      previewImage {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
        alt
      }
      author {
        image {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
          alt
        }
        name
        surname
      }
      tags(sort: "sort:asc") {
        id
        value
        text
      }
      type {
        value
        text
        colorHex
      }
    }
    postTypes (locale: $locale, sort: "sort:asc")  {
      id
      pluralText
      value
    }
    postsCount (where: $where)
  }
`;

interface IBlogPageQuery extends GetServerDataProps {
    query: {
        tag?: string
    }
}

export async function getServerData({ query: params }: IBlogPageQuery): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const where: {
            tags?: number,
            locale: string | undefined,
            isArchived: boolean
        } = {
            locale    : process.env.GATSBY_LOCALE_CODE,
            isArchived: false
        };
        const tag = params?.tag ? parseInt(params.tag, 10) : null;
        if(tag) {
            where.tags = tag;
        }

        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE,
            where
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        return {
            props: {
                blogPage   : data.data.blogPage,
                posts      : data.data.posts,
                postTypes  : data.data.postTypes,
                postsCount : data.data.postsCount,
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer,
                tag        : tag
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
